import { Location } from '~/types/api'

const naCountryCodes = ['US', 'CA', 'MX']
const euroCountryCodes = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'GB',
]
const asiaCountryCodes = [
    'AZ',
    'AF',
    'BH',
    'BD',
    'AM',
    'BT',
    'IO',
    'BN',
    'MM',
    'KH',
    'LK',
    'CN',
    'TW',
    'CX',
    'CC',
    'CY',
    'GE',
    'PS',
    'HK',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IL',
    'JP',
    'KZ',
    'JO',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LB',
    'MO',
    'MY',
    'MV',
    'MN',
    'OM',
    'NP',
    'PK',
    'PH',
    'TL',
    'QA',
    'RU',
    'SA',
    'SG',
    'VN',
    'SY',
    'TJ',
    'TH',
    'AE',
    'TR',
    'TM',
    'UZ',
    'YE',
    'XE',
    'XD',
    'XS',
]

export function groupLocationsByRegion(locations: Location[]) {
    return {
        'North America': locations.filter((location) =>
            naCountryCodes.includes(location.countryCode2)
        ),
        Europe: locations.filter((location) =>
            euroCountryCodes.includes(location.countryCode2)
        ),
        Asia: locations.filter((location) =>
            asiaCountryCodes.includes(location.countryCode2)
        ),
    }
}
