import { useQuery } from '@tanstack/vue-query'

import { Location } from '~/types/api'

export interface GetTopLocationsResponse {
    locations: Location[]
}

async function getTopLocations(): Promise<GetTopLocationsResponse> {
    // return await makeRequest({
    //     path: `/locations/top`,
    //     method: 'GET',
    // })
    return { locations: [] } as GetTopLocationsResponse
}

export async function useTopLocations() {
    const { data, suspense } = useQuery({
        queryKey: ['locations', 'top'],
        queryFn: getTopLocations,
        staleTime: Infinity,
        suspense: true,
    })

    await suspense()
    const { locations } = toRefs(data.value!)
    return locations
}
